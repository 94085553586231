"use strict";

import Vue from 'vue';
import axios from "axios";
import {getToken, removeLocalStorage} from "@/utils/storage";
import router from "@/router";

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers.common.Authorization = getToken() || 'Basic YnJvd3Nlcjo=';


axios.interceptors.request.use(
  async (config) => {
    config = {
      ...config,
      headers: {
        'Authorization': `${getToken() || 'Basic YnJvd3Nlcjo='}`,
        'Accept': 'application/json',
      },
      timeout: 60 * 1000,
      // withCredentials: true,
    }
    // Do something before request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  async (error) => {
    // Do something with response error
    if (error.response.status === 401 || error.response.status === 403) {
      removeLocalStorage('token')
      await router.push('/login')
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      }
    },
    $axios: {
      get() {
        return axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
