const initState = {
  role: null,
  firstName: '',
  lastName: '',
  isAdmin: false
}

const state = {...initState};

const getters = {
  getPermissions(state) {
    return state.role?.permissions || []
  }
};

const mutations = {
  SET_INIT_DATA(state) {
    Object.keys(state).forEach(key => {
      state[key] = initState[key]
    })
  },
  SET_USER_DATA(state, {role, firstName, lastName, isAdmin}) {
    state.role = role
    state.firstName = firstName
    state.lastName = lastName
    state.isAdmin = isAdmin
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
