export default [
  {
    path: '/',
    name: 'Authenticated',
    redirect: {name: 'CoursesList'},
    component: () => import('@/layouts/MainLayout'),
    children: [
      {
        path: 'courses',
        name: 'CoursesList',
        component: () => import('@/views/authenticated/Courses'),
        meta: {
          title: 'Szkolenia',
          public: false,
        },
      },
      {
        path: 'users',
        name: 'UsersList',
        component: () => import('@/views/authenticated/Users'),
        meta: {
          title: 'Użytkownicy',
          public: false,
        },
      },
      {
        path: 'questionnaires',
        name: 'Questionnaires',
        component: () => import('@/views/authenticated/Questionnaires'),
        meta: {
          title: 'Ankiety',
          public: false,
        },
      }
    ]
  }
]
